import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { Loader } from "rsuite";
import { exitModal } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import "./style.css";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const AddCook = () => {
  const location = useLocation();
  const { language } = UseGeneral();
  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const hasOptions = location?.state?.hasOptions;
  const [addLoading, setAddLoading] = useState(false);
  const { id } = useParams();
  const [newProd, setNewProd] = useState({
    price: "",
    discount: "",
    hasOptions: hasOptions ? 1 : 0,
    count: '',
    pricePerUnit: '',
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [isKilo, setIsKilo] = useState(false);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [uploadImages, setUploadImages] = useState(false);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/findall?${token}`)
      .then((res) => {
        setCategories(res?.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [productData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [hasOptions, setHasOptions] = useState(false);
  /*
 {
    "id": 101,
    "ingrendients": "معكرونة سيدي هشام  بالباشاميل  المقادير - لصلصة البشاميل: 1.\tحليب : ليتر 2.\tالزبدة : 3 ملاعق صغيرة 3.\tطحين سيدي هشام : 5 ملاعق كبيرة 4.\tفلفل أسود مطحون سيدي هشام : ملعقة صغيرة 5.\tملح : ملعقة صغيرة 6.\tجبنة شيدر : نصف كوب 7.\tجبن موزاريلا : نصف كوب - المعكرونة: 400 غراماً معكرونة سيدي هشام (بالشكل الأسطواني، مسلوقة ومصفاة بحسب التعليمات المدونة على العبوة) - لحم مفروم: 300 غراماً - صلصة طماطم: ملعقتان كبيرتان - بهارات مشكلة سيدي هشام: ملعقة صغيرة - القرفة سيدي هشام: ملعقة صغيرة - فلفل أسود سيدي هشام: ملعقة صغيرة - كركم مطحون سيدي هشام: ملعقة صغيرة - ملح: ملعقة صغيرة - زيت ذرة: 4 ملاعق كبيرة - الماء: نصف كوب - جبنة شيدر: ربع كوب (للوجه) - جبن موزاريلا: ربع كوب (للوجه)",
    "ingredients_en": " For the bechamel sauce: 1. Milk: a liter 2. Butter: 3 teaspoons 3. Sidi Hisham flour: 5 tablespoons 4. Ground black pepper Sidi Hisham: a teaspoon 5. Salt: one teaspoon 6. Cheddar cheese: half a cup 7. Mozzarella cheese: half a cup Pasta: 400 grams of Sidi Hisham pasta (in a cylindrical shape, boiled and drained according to the instructions on the package) Minced meat: 300 grams Tomato sauce: two tablespoons Mixed spices, Sidi Hisham: one teaspoon Cinnamon Sidi Hisham: one teaspoon - Black pepper Sidi Hisham: one teaspoon Ground turmeric, Sidi Hisham: one teaspoon Salt: one teaspoon Corn oil: 4 tablespoons Water: half a cup - Cheddar cheese: a quarter cup (for the face) Mozzarella cheese: a quarter cup (for the face)",
    "image_background_url": "uploads/cooks/slider_images/ÙØ¹ÙØ±ÙÙØ©Ø¨Ø§ÙØ¨Ø´Ø§ÙÙÙa6829d20-7008-447f-a07c-18675cdfbbc9.jpg",
    "video_url": "https://youtu.be/knc1yGvQPRo",
    "video_url_instead_slider": "https://youtu.be/knc1yGvQPRo",
    "deletedAt": null,
    "CreatedAt": "2022-12-20T11:16:44.597Z",
    "updatedAt": "2024-03-25T06:49:06.493Z",
    "cooks_categoryId": 41,
    "cooks_images": [
        {
            "id": 520,
            "image_url": "uploads/cooks/slider_images/ÙØ¹ÙØ±ÙÙØ©Ø¨Ø§ÙØ¨Ø´Ø§ÙÙÙ2857ad9c-34f5-4b73-b480-d2d14822a924.webp",
            "cooks_id": 101
        }
    ],
    "recommended_products": [
        {
            "id": 254,
        }
    ],
    "cooks_translations": [
        {
            "id": 725,
            "locale": "ar",
            "name": "معكرونة سيدي هشام  بالباشاميل ",
            "description": "<p style=\"text-align: right;\">طريقة التحضير :<br>لتحضير البشاميل:<br> 1.ذوبي الزبدة بقدر على النار، ثم أضيفي الطحين بالتدريج مع\nالاستمرار بالتحريك، حتى يتشكل لديك مزيج متجانس، ثم إبدأي بإضافة الحليب السائل\nعلى دفعات، مع التحريك، أضيفي الفلفل الأسود، والملح، وجبنة الشيدر والموزاريلا.<br>\n2.\nلإعداد الصوص: ضعي كمية الزيت بقدر على النار، ثم أضيفي كمية اللحم المفروم، بهرّي\nاللحم بالفلفل الأسود، والملح، والكركم، والقرفة، والبهارات المشكلة، وقلبي اللحم\nمع البهارات، ثم أضيفي صلصة الطماطم، والماء، واتركي خليط اللحم حتى يتسبك قليلاً.\n3. توضع المكرونة\nالمسلوقة بالقالب، أو بصينية الفرن، وتضاف كمية من خلطة البشاميل، ثم طبقة أخرى من\nخليط اللحم المفروم، وطبقة أخيرة من خلطة البشاميل، وتزين بجبنة الشيدر،\nوالموزاريلا، وتدخل للفرن على درجة حرارة 180 لمدة 30 دقيقة، وتقدم ساخنة.</p>",
            "cooks_id": 101
        },
        {
            "id": 726,
            "locale": "en",
            "name": "Sidi Hisham pasta with bechamel",
            "description": "<p>How to prepare\n1. To\nprepare the béchamel: Melt the butter in a saucepan over the stove, then add\nthe flour gradually while continuing to stir, until you have a homogeneous\nmixture, then start adding the liquid milk in batches, while stirring, add the\nblack pepper, salt, cheddar, and\nmozzarella cheese.\n2. To\nprepare the sauce: Put the amount of oil in a pot on the stove, then add the\namount of minced meat, spice the meat with black pepper, salt, turmeric,\ncinnamon, and mixed spices, and stir the meat with the spices, then add the\ntomato sauce, water, and leave the meat mixture until it browns a little.\n3. Boiled pasta is placed in the mold, or in the oven tray, and a quantity\nof the bechamel mixture is added, then another layer of the minced meat\nmixture, and a final layer of the bechamel mixture, and decorated with cheddar\nand mozzarella cheese, and entered into the oven at 180 degrees for 30 minutes,\nand served hot</p>",
            "cooks_id": 101
        }
    ]
} */

  useEffect(() => {
    getCategories();
    getAllProducts();
    if (id) {
      getProductById();
    }
    getSubCategories();
  }, [id, language]);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?
    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .get(`${BASE_URL}products/allProducts`)
      .then((res) => {
        console.log(res);
        setSubCategories(res?.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {}, []);

  const getAllProducts = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?${token}`)
      .then((res) => {
        // console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          // console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == 'faild') {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : 'Something Went Error');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [selectedOptions, setSelectedOptions] = useState(null);

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };
  const handleDeleteOldImages = (index) => {
    const updatedImages = [...imagesUrl];
    updatedImages.splice(index, 1);
    setImagesUrl(updatedImages);
  };
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleAddNewProduct = async () => {
   console.log(imagesUrl)
    const dataSet = {
      image_url: imagesUrl,
      ...newProd,
      photo_slider_urls:imagesUrl,

      id_category_cook: parseInt(newProd?.cooks_categoryId),
      recommended_product_ids: newProd?.recommended_product_ids?.map((item) =>
        parseInt(item?.value)
      ),
    };

    const token = localStorage.getItem("SediHishamAdmindata");

    try {
      const response = await fetch(`${BASE_URL}cooks/update/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataSet),
      });

      const res = await response.json();

      if (response.ok) {
        toast.success("Success");
     
      } else {
        toast.error(language === 'ar' ? "حدث خطأ ما" : 'Something Went Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddLoading(false);
      // setNewCat({
      //   title_ar: "",
      //   title_en: "",
      //   color: "",
      // });
      // setSelectedFile(null);
    }
  };

  const handleUploadImages = async () => {
    const arr = [];
    setUploadImages(true);
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("file", selectedImages[i]);
      await axios
        .post(`${BASE_URL}cooks/upload/cook-image`, formData)
        .then((res) => {
          arr.push(res?.data?.imagePath);
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);
    setUploadImages(false);

    setImagesUrl([...imagesUrl,...arr]);
  };

  const [productPdf, setProductPdf] = useState(null);
  const handleUploadFiles = async () => {
    const formData = new FormData();
    formData.append("file", productPdf);
    await axios
      .post(`${BASE_URL}products/upload/product-pdf`, formData)
      .then((res) => {
        setNewProd({ ...newProd, product_pdf: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [productSku, setProductSku] = useState(null);
  const handleUploadSKU = async () => {
    const formData = new FormData();
    formData.append("file", productSku);
    await axios
      .post(`${BASE_URL}cooks/upload/cook-image`, formData)
      .then((res) => {
        setNewProd({ ...newProd, image_background_url: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  const getProductById = async () => {
    await axios
      .get(`${BASE_URL}cooks/find/${id}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log("res?.data", res?.data);
        setNewProd({
          name_ar: res?.data?.cooks_translations[0]?.name,
          name_en: res?.data?.cooks_translations[1]?.name,
          description_ar: res?.data?.cooks_translations[0]?.description,
          description_en: res?.data?.cooks_translations[1]?.description,
          ...res?.data,
          recommended_product_ids: res?.data?.recommended_products?.map(
            (item) => ({
              label:
                language == "ar"
                  ? item?.product_translations[0]?.nameProduct
                  : item?.product_translations[1]?.nameProduct,
              value: parseInt(item.id),
            })
          ),
          ingredients: res?.data?.ingrendients,
        });
        setImagesUrl(res?.data?.cooks_images?.map((item) => item?.image_url));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setDataLoading(false);
      });
  };

  useEffect(() => {
    console.log("newProd", newProd);
  }, [newProd]);

  return (
    <>
      {!newProd?.id ? (
        <Loader />
      ) : (
        <div className="add_product_container">
          <div className="d-flex mb-5 mt-3 align-items-center ">
            <h3 className="">
              {language == 'ar' ? "إضافة منتج جديد" : "Add New Product"}
            </h3>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewProduct();
            }}
          >
            <div className="fields row gy-4">
              <div className="field_input  col-md-6">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar'
                      ? ' اسم المنتج باللغة '
                      : ' Product Name In '}
                    {lang.prod_name
                      ? language == 'ar'
                        ? "الإنجليزية"
                        : " english "
                      : language == 'ar'
                      ? " العربية "
                      : " arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          prod_name: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <textarea
                  // type='text'
                  value={lang.prod_name ? newProd.name_en : newProd.name_ar}
                  onChange={(e) => {
                    lang.prod_name
                      ? setNewProd({
                          ...newProd,
                          name_en: e.target.value,
                        })
                      : setNewProd({
                          ...newProd,
                          name_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>
                  {language == 'ar'
                    ? "الوصف بالانجليزية"
                    : "Description Into English"}
                </h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.description_en}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, description_en: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>{language == 'ar' ? "الوصف" : "Description"}</h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.description_ar}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, description_ar: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>
                  {language == 'ar'
                    ? "المكونات بالانجليزية"
                    : "Ingrediants Into English"}
                </h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.ingredients}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, ingredients: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>{language == 'ar' ? "المكونات" : "Ingrediants"}</h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.ingredients_en}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, ingredients_en: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              {categories && categories?.length ? (
                <>
                  <div className="field_input col-md-6">
                    <label htmlFor="">
                      {language == 'ar' ? 'المنتجات' : 'Products'}
                    </label>
                    <Select
                      placeholder={language == 'ar' ? "المنتجات " : 'Products'}
                      isMulti
                      value={newProd?.recommended_product_ids}
                      onChange={(e) => {
                        console.log("e", e);
                        setNewProd({
                          ...newProd,
                          recommended_product_ids: e,
                        });
                      }}
                      // value={selectedSubCategories}
                      name="subcategories"
                      options={
                        subCategories &&
                        subCategories != null &&
                        Array.isArray(subCategories) &&
                        subCategories.length > 0 &&
                        subCategories != null &&
                        subCategories.map((item, index) => {
                          return {
                            label:
                              language == "ar"
                                ? item?.product_translations[0]?.nameProduct
                                : item?.product_translations[1]?.nameProduct,
                            value: parseInt(item.id),
                          };
                        })
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className="images_container my-3 porder prorder-2 rounded">
              <h4>
                {language == 'ar'
                  ? "اختر صور السلايدر"
                  : "Enter Cook Slider Images"}
              </h4>

              <div className="images">
                <label className="image" htmlFor="mul_images">
                  <img
                    className="avatar"
                    src={
                      "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                    }
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  multiple
                  name=""
                  id="mul_images"
                  className="d-none"
                  onChange={handleImageChange}
                />
                {selectedImages.length >= 1
                  ? selectedImages.map((imageFile, index) => {
                      return (
                        <div className="image">
                          <img src={URL.createObjectURL(imageFile)} alt="" />
                          <div
                            className="delete_btn"
                            onClick={() => handleDeleteImage(index)}
                          >
                            {exitModal}
                          </div>
                        </div>
                      );
                    })
                  : null}
                {imagesUrl.length >= 1
                  ? imagesUrl.map((imageFile, index) => {
                      return (
                        <div className="image">
                          <img src={BASE_URL + imageFile} alt="" />
                          <div
                            className="delete_btn"
                            onClick={() => handleDeleteOldImages(index)}
                          >
                            {exitModal}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (!uploadImages) {
                    handleUploadImages();
                  }
                }}
                className="popup_agree_btn"
              >
                {uploadImages ? (
                  <Loader />
                ) : language == 'ar' ? (
                  "رفع الصور"
                ) : (
                  "Upload Image"
                )}
              </button>
            </div>

            {/* start new  */}

            <div className="my-4">
              <div className="images_container my-3 porder prorder-2 rounded">
                <h4>
                  {language == 'ar' ? "اختر صورة الطبخة" : "Enter Cooks Image"}
                </h4>

                <div className="images">
                  <label className="image" htmlFor="SKU">
                    <img
                      className="avatar"
                      src={
                        "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                      }
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    multiple
                    name=""
                    id="SKU"
                    className="d-none"
                    onChange={(e) => setProductSku(e.target.files[0])}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleUploadSKU();
                  }}
                  className="popup_agree_btn"
                >
                  {uploadImages ? (
                    <Loader />
                  ) : language == 'ar' ? (
                    "رفع صورة "
                  ) : (
                    "Upload Image"
                  )}
                </button>
              </div>
            </div>

            <div className="my-4">
              <div className="images_container my-3 porder prorder-2 rounded">
                <h4>
                  {language == 'ar'
                    ? "رابط الفيديو ف السلايدر"
                    : "Enter Cook Video Link In slider"}
                </h4>

                <input
                  type="text"
                  name=""
                  id=""
                  value={newProd?.video_url_instead_slider}
                  onChange={(e) =>
                    setNewProd({
                      ...newProd,
                      video_url_instead_slider: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-4">
              <div className="images_container my-3 porder prorder-2 rounded">
                <h4>
                  {language == 'ar'
                    ? "رابط الفيديو"
                    : "Enter Product Video Link"}
                </h4>

                <input
                  type="text"
                  name=""
                  id=""
                  value={newProd?.video_url}
                  onChange={(e) =>
                    setNewProd({
                      ...newProd,
                      video_url: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <button disabled={addLoading} className="popup_agree_btn">
              {addLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default AddCook;
