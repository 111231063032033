import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "rsuite";
import { BASE_URL } from "../../components/Axios/base_uri";
import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";
import ImageUpload from "./ImageUpload";
import VideoLinksInput from "./VideoLinksInput"; // Import VideoLinksInput component
import toast from "react-hot-toast";

const Pages = () => {
  const location = useLocation();
  const { language } = UseGeneral();
  const [addLoading, setAddLoading] = useState(false);
  const [mainSliderVideoLink, setMainSliderVideoLink] = useState("");

  // State for main slider images
  const [mainSliderImages, setMainSliderImages] = useState([]);
  const [mainSliderImagesUrl, setMainSliderImagesUrl] = useState([]);

  // State for secondary slider images
  const [secondarySliderImages, setSecondarySliderImages] = useState([]);
  const [secondarySliderImagesUrl, setSecondarySliderImagesUrl] = useState([]);

  // State for photos & videos gallery
  const [photosVideosGallery, setPhotosVideosGallery] = useState([]);
  const [photosVideosGalleryUrl, setPhotosVideosGalleryUrl] = useState([]);

  // State for trademarks
  const [trademarksImages, setTrademarksImages] = useState([]);
  const [trademarksImagesUrl, setTrademarksImagesUrl] = useState([]);

  // State for video links
  const [videoLinks, setVideoLinks] = useState([
    "https://youtu.be/sbb82FkmRyo",
    "https://youtu.be/aTFmyHJRTx0",
    "https://youtu.be/7p8ESY4fctA",
    "https://youtu.be/_3_g2QEodzE",
  ]);

  // State for catalog PDF upload
  const [catalogPdfFile, setCatalogPdfFile] = useState(null);
  const [catalogPdfUrl, setCatalogPdfUrl] = useState("");

  // Handle file change for catalog PDF upload
  const handleFileChange = (e) => {
    setCatalogPdfFile(e.target.files[0]);
  };

  // Handle PDF upload
  const handleUploadPdf = async (e) => {
    e.preventDefault();
    setAddLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", catalogPdfFile);

      const res = await axios.post(
        `${BASE_URL}pages/upload/home-page/pdf`,
        formData
      );

      setCatalogPdfUrl(res.data?.pdf_url); // Assuming the response has a key 'pdf_url' with the uploaded file URL

      console.log("Uploaded PDF URL:", res.data?.pdf_url);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }

    setAddLoading(false);
  };
  const handleUploadImages = async (selectedImages, setImagesUrl, category) => {
    const arr = [];
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("file", selectedImages[i]);
      try {
        const res = await axios.post(
          `${BASE_URL}categories/upload/category-image`,
          formData
        );
        arr.push(res?.data?.imagePath);
      } catch (error) {
        console.log(error);
      }
    }
    setImagesUrl((prevImagesUrl) => [...prevImagesUrl, ...arr]);
  };
  // Fetch product details by ID
  const getProductById = async () => {
    try {
      const res = await axios.get(`${BASE_URL}pages/getall/home`, {
        headers: {
          lang: language,
        },
      });
      setCatalogPdfUrl( res?.data
        ?.filter((item) => item?.categoryImage === "catalog")
        .map((item) => item?.path_image)[0])
      setMainSliderImagesUrl(
        res?.data
          ?.filter((item) => item?.categoryImage === "home_page_main_slider")
          .map((item) => item?.path_image)
      );
      setSecondarySliderImagesUrl(
        res?.data
          ?.filter((item) => item?.categoryImage === "home_page_second_slider")
          .map((item) => item?.path_image)
      );
      setPhotosVideosGalleryUrl(
        res?.data
          ?.filter((item) => item?.categoryImage === "gallery_image")
          .map((item) => item?.path_image)
      );
      setTrademarksImagesUrl(
        res?.data
          ?.filter((item) => item?.categoryImage === "trademarks")
          .map((item) => item?.path_image)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductById();
  }, [language]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddLoading(true);

    const data_send = {
      image_slider_url: mainSliderImagesUrl,
      video_link_home_page: [mainSliderVideoLink],
      catalog_url: catalogPdfUrl,
      image_slider2_url: secondarySliderImagesUrl,
      images_product_url: photosVideosGalleryUrl,
      videolinks: videoLinks,
      trademarks_imag_url: trademarksImagesUrl,
    };

    try {
      const response = await fetch(`${BASE_URL}pages/home/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data_send),
      });

      const res = await response.json();

      if (response?.ok) {
        toast.success("New Added Successfully");
        getProductById();
      } else if (res.status === "error" || res.status === "faild") {
        toast.error(res.message);
      } else {
        toast.error(language === 'ar' ? "حدث خطأ ما" : 'Something Went Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddLoading(false);
    }

    setAddLoading(false);
  };

  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3>{language === 'ar' ? "الصفحة الرئيسية" : "Home Page"}</h3>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Main Slider Images */}
        <ImageUpload
          title={
            language === 'ar' ? "السلايدر الرئيسي" : "Home page main slider"
          }
          selectedImages={mainSliderImages}
          setSelectedImages={setMainSliderImages}
          imagesUrl={mainSliderImagesUrl}
          setImagesUrl={setMainSliderImagesUrl}
          handleUploadImages={(images, setImagesUrl) =>
            handleUploadImages(images, setImagesUrl, "home_page_main_slider")
          }
          uploadImages={addLoading}
          language={language}
        />

        {/* Secondary Slider Images */}
        <ImageUpload
          title={
            language === 'ar'
              ? "السلايدر الثانوي"
              : "Home page secondary slider"
          }
          selectedImages={secondarySliderImages}
          setSelectedImages={setSecondarySliderImages}
          imagesUrl={secondarySliderImagesUrl}
          setImagesUrl={setSecondarySliderImagesUrl}
          handleUploadImages={(images, setImagesUrl) =>
            handleUploadImages(images, setImagesUrl, "home_page_second_slider")
          }
          uploadImages={addLoading}
          language={language}
        />

        {/* Photos & Videos Gallery */}
        <ImageUpload
          title={
            language === 'ar'
              ? "معرض الصور والفيديوهات"
              : "Photos & Videos Gallery"
          }
          selectedImages={photosVideosGallery}
          setSelectedImages={setPhotosVideosGallery}
          imagesUrl={photosVideosGalleryUrl}
          setImagesUrl={setPhotosVideosGalleryUrl}
          handleUploadImages={(images, setImagesUrl) =>
            handleUploadImages(images, setImagesUrl, "gallery_image")
          }
          uploadImages={addLoading}
          language={language}
        />

        {/* Trademarks Images */}
        <ImageUpload
          title={language === 'ar' ? "العلامات التجارية" : "Trademarks"}
          selectedImages={trademarksImages}
          setSelectedImages={setTrademarksImages}
          imagesUrl={trademarksImagesUrl}
          setImagesUrl={setTrademarksImagesUrl}
          handleUploadImages={(images, setImagesUrl) =>
            handleUploadImages(images, setImagesUrl, "trademarks")
          }
          uploadImages={addLoading}
          language={language}
        />

        {/* Main Slider Video Link */}
        <div className="form-group">
          <label htmlFor="mainSliderVideoLink">
            {language === "ar"
              ? "رابط الفيديو للسلايدر الرئيسي"
              : "Main Slider Video Link"}
          </label>
          <input
            type="text"
            className="form-control"
            id="mainSliderVideoLink"
            value={mainSliderVideoLink}
            onChange={(e) => setMainSliderVideoLink(e.target.value)}
            placeholder={
              language === "ar" ? "أدخل رابط الفيديو" : "Enter Video Link"
            }
          />
        </div>

        {/* Video Links Input */}
        <div className="mt-4">
          <h4>{language === 'ar' ? "روابط الفيديوهات" : "Video Links"}</h4>
          <VideoLinksInput initialLinks={videoLinks} onChange={setVideoLinks} />
        </div>

        {/* Catalog PDF Upload */}
        <div className="form-group">
          <label htmlFor="catalogPdfFile">
            {language === "ar" ? "رفع ملف الكتالوج PDF" : "Upload Catalog PDF"}
          </label>
          <input
            type="file"
            className="form-control-file"
            id="catalogPdfFile"
            onChange={handleFileChange}
            accept=".pdf"
          />
          {catalogPdfFile && (
            <button
              className="btn btn-primary mt-2"
              onClick={handleUploadPdf}
              disabled={addLoading}
            >
              {addLoading ? <Loader /> : language === "ar" ? "تحميل" : "Upload"}
            </button>
          )}
        </div>

        {/* Display Uploaded PDF URL */}
        {catalogPdfUrl && (
          <div className="mt-3">
            <p>
             
              <a href={BASE_URL+catalogPdfUrl} target="_blank" rel="noopener noreferrer">
                {"catalog"}
              </a>
            </p>
          </div>
        )}

        {/* Submit Button */}
        <button disabled={addLoading} className="popup_agree_btn">
          {addLoading ? <Loader /> : language === 'ar' ? "تعديل" : "Edit"}
        </button>
      </form>
    </div>
  );
};

export default Pages;
