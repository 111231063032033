import React from 'react';
import { LineChart } from 'react-charts';
import { Chart } from 'react-charts/dist/react-charts.development';

const Line = ({ data }) => {
  // Format data for react-charts
  const chartData = React.useMemo(
    () =>
      data.map(dataset => ({
        label: dataset.label,
        data: dataset.data.map(item => [item.primary, item.secondary])
      })),
    [data]
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  );

  return (
    <div
      style={{
        width: '100%',
        height: '300px'
      }}
    >
      <Chart data={chartData} axes={axes} />
    </div>
  );
};

export default Line;
