import { Children, useState } from "react";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import PopUp from "../../components/popup";

export default function ShipmentFee() {
  const language = UseGeneral();
  const [allFee , setAllFee] = useState([]);
  const [isShowAddModal,setIsShowAddModal] = useState(false);
  const [feeData , setFeeData] = useState({
    name_ar :"",
    name_en :"",
    fee_coupon:"",
  })
  const headers = [
    {
      label:"ID",
      dataIndex:"",
    },
    {
      label:"Location Name",
      dataIndex:"name_ar"
    },
    {
      label:"Additional Fee",
      dataIndex:"fee_coupon",
    },
    {
      label:"Creation Date",
      dataIndex:""
    },
    {
      label:"",
      type:"children",
      children : ({row}) => {
        return (
          <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <button  style={{backgroundColor :"red"  , color:"white" , borderRadius:"5px"}}>
                    <svg style={{width:"20px" , height:"15px"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
      </svg>
                    </button>  
   
                </div>
        )
      }
    }
  ]
  return (
    <div className="rowDiv flex-2-1 page_padding">
       <div>
        <div className="title_add">
            {/* <h5>{language=='ar'?"رسوم الشحن":"Shipment Fee"}</h5> */}
            <div style={{marginLeft:"auto"}} onClick={() => setIsShowAddModal(true)} className="btn btn-success">{language == 'ar' ? 'رسوم الشحن' : 'Add Shipment Fee'}</div>
          </div>
        </div>
        <PopUp title="Add New Shipment Fee" open={isShowAddModal} setOpen={setIsShowAddModal}>
          <form>
             <div className="field_input">
               <label>Enter Arabic Name</label>
               <input type="text" placeholder="Enter Arabic Name"/>
             </div>

             <div className="field_input">
               <label>Enter English Name</label>
               <input type="text" placeholder="Enter English Name"/>
             </div>

             <div cclassName="field_input">
               <label>Enter Fee</label>
               <input type="number" placeholder="Enter Fee"/>
             </div>

             <div style={{display:"flex" , gap:"10px"}}>
                        <button type="submit" className='popup_agree_btn'>Add</button>
                        <button  onClick={() => setIsShowAddModal(false)} style={{padding:"5px 10px" , marginTop :"20px" ,borderRadius:"3px"}}>Cancel</button>
                        </div>
          </form>
        </PopUp>
      <TableLayout headers={headers} data={allFee}/>
    </div>
  )
}
