import React, { useEffect, useState } from 'react';
import Sats from '../../components/stats';
import TableLayout from '../../components/table';
import "./style.css";
import axios from 'axios';
import { BASE_URL } from '../../components/Axios/base_uri';
import toast from 'react-hot-toast';
import { Loader } from 'rsuite';
import UseGeneral from '../../customHooks/useGeneral';
import Orders from '../orders';
import Line from '../../components/charts/Line';
import BarChart from '../../components/charts/barCharts';

const Home = () => {
  const { language } = UseGeneral();
  const [categories, setCategories] = useState(null);
  const [dataLoading, setDataLoading] = useState(null);
  const [lineData, setLineData] = useState([]);
  const [selectedDataType, setSelectedDataType] = useState('Orders');
  const [selectedFrequency, setSelectedFrequency] = useState('Monthly');

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}statistics`)
      .then((res) => {
        console.log(res);
        setCategories(res?.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.data && Array.isArray(response.data)) {
        const transformedData = [
          {
            label: 'User Data',
            data: response.data.map((item) => ({
              primary: item.month,
              secondary: item.userGain,
            })),
          },
        ];
        setLineData(transformedData);
      } else {
        console.error('Invalid data format:', response.data);
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    getCategories();
    fetchData(getApiUrl());
  }, [selectedDataType, selectedFrequency]);

  const getApiUrl = () => {
    let apiUrl;
    if (selectedDataType === 'Orders') {
      apiUrl = 'https://api.camp-coding.site/statistics/graph/';
    } else if (selectedDataType === 'Sales') {
      apiUrl = 'https://api.camp-coding.site/statistics/graph/';
    }

    apiUrl += selectedFrequency === 'Monthly' ? 'monthly' : 'weekly';
    apiUrl += selectedDataType === 'Orders' ? '/orders' : '';

    return apiUrl;
  };

  return (
    <>
      {!categories ? (
        <Loader size="lg" style={{ margin: "30px" }} />
      ) : categories ? (
        <div className="columnDiv">
          <Sats stats={{ ...categories }} />
          <div className="rowDiv" id="rowDiv">
            <div className="container" id="container">
              <div className="selectors" id="selectors">
                <select
                  id="dataType"
                  className="data-type"
                  value={selectedDataType}
                  onChange={(e) => {
                    setSelectedDataType(e.target.value);
                    setSelectedFrequency('Monthly'); // Set default frequency to Monthly
                  }}
                >
                  <option value="Orders">Orders</option>
                  <option value="Sales">Sales</option>
                </select>

                <select
                  id="frequency"
                  className="frequency"
                  value={selectedFrequency}
                  onChange={(e) => setSelectedFrequency(e.target.value)}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Weekly">Weekly</option>
                </select>
              </div>

              {!lineData ? (
                <div className="loader-container" id="loaderContainer">
                  <Loader />
                </div>
              ) : lineData?.length ? (
                <>
                  {" "}
                  
                  <Line data={lineData} />
                  <BarChart data={categories} />
                </>
              ) : null}
            </div>
          </div>
          <Orders hideFilters={true} />
        </div>
      ) : language === 'ar' ? (
        "فشل في جلب البيانات"
      ) : (
        "Failed To Get Data"
      )}
    </>
  );
};

export default Home;
