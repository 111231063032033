import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import 'rsuite/Loader/styles/index.css';
import "./App.css";
import Settings from "./pages/Settings/Settings";
import GeneralSettings from "./pages/GeneralSettings/GeneralSettings";
import Preferences from "./pages/Preferences/Preferences";
import ShipmentFee from "./pages/ShipmentFee/ShipmentFee";
import Currencies from "./pages/Currencies/Currencies";
import { BorderTopSpinner } from "./components/loaders";
import "./components/loaders/style.css";
import UseGeneral from "./customHooks/useGeneral";
import DefaultLayout from "./layouts/defaultLayouts";
import Brands from "./pages/Brands/Brands";
import CommonQuestions from "./pages/Common questions/CommonQuestions";
import IdeasPage from "./pages/IdeasPage/IdeasPage";
import AboutSitePage from "./pages/aboutSitePage/aboutSitePage/AboutSitePage";
import AddIdeaPage from "./pages/addIdaeasPage/IdeasPage";
import AddProductPage from "./pages/addProductPage/AddProductPage";
import Admin from "./pages/admins";
import BannersPage from "./pages/bannersPage/BannersPage";
import CategoriesPage from "./pages/categories";
import CategoryBorductsPage from "./pages/categoryBorductsPage/CategoryBorductsPage";
import ContactsPage from "./pages/contactsPage/ContactsPage";
import CouponsPage from './pages/couponsPage/CouponsPage';
import DistrictsPage from './pages/districtsPage/DistrictsPage';
import EditProductIdeasPage from "./pages/editProductIdeasPage/EditProductIdeasPage";
import EditProductPage from "./pages/editProductPage/EditProductPage";
import FooterDetailsPage from "./pages/footerDetailsPage/FooterDetailsPage";
import Home from "./pages/home";
import IdeasProductsPage from "./pages/ideasProductsPage/IdeasProductsPage";
import Login from "./pages/login";
import Notifications from "./pages/notifications/Notifications";
import Orders from "./pages/orders";
import ProductsPage from "./pages/productsPage/ProductsPage";
import RejionsPage from "./pages/rejionsPage/RejionsPage";
import Sections from "./pages/sections";
import SubCategory from "./pages/subCategoryPage/SubCategory";
import { fetchUserData } from "./store/reducers/userReducer";
import ArchivedCategoriesPage from "./pages/categories/archivedCategories";
import ArchivedProductsPage from "./pages/productsPage/ArchivedProductsPage";
import CategoryProducts from "./pages/categoryBorductsPage/CategoryProducts";
import BrandsArchive from "./pages/Brands/BrandsِArchive";
import BannersArchive from "./pages/bannersPage/BannersArchive";
import SectionsArchive from "./pages/sections/SectionsArchive";
import SubCategoryArchived from "./pages/subCategoryPage/SubCategoryArchived";
import ArchivedIdeasPage from "./pages/IdeasPage/ArchivedIdeasPage";
import RejionsArchive from "./pages/rejionsPage/RejionsArchive";
import DistrictsArchive from "./pages/districtsPage/DistrictsArchive";
import Messages from "./pages/messages";
import AddCook from "./pages/addProductPage/AddCook";
import Jops from "./pages/orders/Jops";
import JopsManagment from "./pages/orders/JopsManagment";
import Coupons from "./pages/Coupons/Coupons";
import Users from "./pages/Users/Users";
import Customers from "./pages/Customers/Customers";
import News from "./pages/ideasProductsPage/news";
import AddNews from "./pages/addProductPage/AddNews";
import AddActivity from "./pages/addProductPage/Add Activity";
import Activity from "./pages/ideasProductsPage/activity";
import EditNews from "./pages/addProductPage/EditNews";
import EditِActivity from "./pages/addProductPage/EditActivity";
import Pages from "./pages/pages";
function App() {
  const dispatch = useDispatch();

  const { userData } = UseGeneral();
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  return (
    <>
      {userData?.loading ? (
        <div className="loader">
          <BorderTopSpinner />
        </div>
      ) : !userData?.loggedIn ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <DefaultLayout>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route
              path="/categories/:id/:category_id"
              element={<SubCategory />}
            />
            <Route
              path="/subcategories/:id/:category_id"
              element={<CategoryBorductsPage />}
            />
            <Route path="/addproduct" element={<AddProductPage />} />
            <Route path="/editporduct/:id" element={<EditProductPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/addideas" element={<AddIdeaPage />} />
            <Route path="/ideas" element={<IdeasPage />} />
            <Route path="/ideas/:id" element={<IdeasProductsPage />} />
            <Route path="/about" element={<AboutSitePage />} />
            <Route path="/Sections" element={<Sections />} />
            <Route path="/BrandsArchive" element={<BrandsArchive />} />
            <Route path="/BannersArchive" element={<BannersArchive />} />
            <Route path="/SectionsArchive" element={<SectionsArchive />} />
            <Route path="/ArchivedIdeas" element={<ArchivedIdeasPage />} />
            <Route path="/RejionsArchive" element={<RejionsArchive />} />
            <Route path="/AddNews" element={<AddNews />} />
            <Route path="/AddActivity" element={<AddActivity />} />
            <Route path="/Activity" element={<Activity />} />
            <Route path="/Jops" element={<Jops />} />
            <Route path="/JopsManagment" element={<JopsManagment />} />
            <Route path="/News" element={<News />} />
            <Route path="/DistrictsArchive" element={<DistrictsArchive />} />
            <Route path="/AddCook/:id" element={<AddCook />} />
            <Route path="/EditNews/:id" element={<EditNews />} />
            <Route path="/Editactivities/:id" element={<EditِActivity />} />
            <Route path="/users" element={<Users />}/>
            <Route path="/customers" element={<Customers />}/>
            <Route path="/coupons" element={<Coupons />}/>
            <Route path="/Pages" element={<Pages />}/>
            <Route path="/settings" element={<Settings />}>
              <Route path="generalSettings" element={<GeneralSettings />}/>
              <Route path="preferences" element={<Preferences />}/>
              <Route path="shipmentfee" element={<ShipmentFee />}/>
              <Route path="currencies" element={<Currencies />}/>
            </Route>
            {/* DistrictsArchive */}
            {/* RejionsArchive */}
            {/* ArchivedIdeasPage */}
            <Route
              path="/SubCategoryArchived"
              element={<SubCategoryArchived />}
            />
           <Route
              path="/Messages"
              element={<Messages />}
            /> {/* SubCategoryArchived */}
            {/* SectionsArchive */}
            {/* BannersArchive */}
            {/* BrandsArchive */}
            <Route
              path="/CategoryProducts/:category_id"
              element={<CategoryProducts />}
            />
            {/* CategoryProducts */}
            {/* Sections */}
            <Route
              path="/editProductIdeas/:id"
              element={<EditProductIdeasPage />}
            />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/management" element={<Admin />} />{" "}
            <Route path="/banners" element={<BannersPage />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/rejions" element={<RejionsPage />} />
            <Route path="/rejions/:id" element={<DistrictsPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/coupons" element={<CouponsPage />} />
            <Route path="/footerdetails" element={<FooterDetailsPage />} />
            <Route path="/commonquestions" element={<CommonQuestions />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route
              path="/ArchivedCategories"
              element={<ArchivedCategoriesPage />}
            />
            <Route
              path="/ArchivedProducts"
              element={<ArchivedProductsPage />}
            />
            <Route path="/ArchivedIdeas" element={<ArchivedProductsPage />} />
            {/* ArchivedCategoriesPage */}
          </Routes>
        </DefaultLayout>
      )}

      <div className="toaster" style={{ zIndex: 131232131413141 }}>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              zIndex: '9999999999999999999999',
            },
          }}
        />
      </div>
    </>
  );
}

export default App;
