import React from "react";
import { Loader } from "rsuite";
import { exitModal } from "../../assets/svgIcons";
import "./style.css";
import { BASE_URL } from "../../components/Axios/base_uri";

const ImageUpload = ({
  title,
  selectedImages,
  setSelectedImages,
  imagesUrl,
  setImagesUrl,
  handleUploadImages,
  uploadImages,
  language
}) => {
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleDeleteOldImage = (index) => {
    const updatedImages = [...imagesUrl];
    updatedImages.splice(index, 1);
    setImagesUrl(updatedImages);
  };

  const handleUpload = async () => {
    if (selectedImages.length > 0 && !uploadImages) {
      await handleUploadImages(selectedImages, setImagesUrl);
      setSelectedImages([]); // Reset selectedImages after successful upload
    }
  };

  return (
    <div className="images_container my-3 porder prorder-2 rounded">
      <h4>{title}</h4>
      <div className="images">
        <label className="image" htmlFor={`${title}_images`}>
          <img
            className="avatar"
            src={
              "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
            }
            alt=""
          />
        </label>
        <input
          type="file"
          multiple
          id={`${title}_images`}
          className="d-none"
          onChange={handleImageChange}
        />
        {selectedImages.length >= 1 &&
          selectedImages.map((imageFile, index) => (
            <div className="image" key={index}>
              <img src={URL.createObjectURL(imageFile)} alt="" />
              <div
                className="delete_btn"
                onClick={() => handleDeleteImage(index)}
              >
                {exitModal}
              </div>
            </div>
          ))}

        {imagesUrl.length >= 1 &&
          imagesUrl.map((imageUrl, index) => (
            <div className="image" key={index}>
              <img src={`${BASE_URL}${imageUrl}`} alt="" />
              <div
                className="delete_btn"
                onClick={() => handleDeleteOldImage(index)}
              >
                {exitModal}
              </div>
            </div>
          ))}
      </div>
      <button onClick={handleUpload} className="popup_agree_btn">
        {uploadImages ? (
          <Loader />
        ) : (
          language === "ar" ? "رفع الصور" : "Upload Images"
        )}
      </button>
    </div>
  );
};

export default ImageUpload;
