import React from 'react';
import { Chart } from 'react-charts/dist/react-charts.development';

const HomeChart = ({ data }) => {
  // Preprocess data by dividing each value by 10000
  const processedData = {
    all_Orders: data.all_Orders / 10000,
    active_Orders: data.active_Orders / 10000,
    paid_Orders: data.paid_Orders / 10000,
    canceled_Orders: data.canceled_Orders / 10000,
    pending_Orders: data.pending_Orders / 10000,
    revenue: data.revenue / 10000,
    in_stock_products: data.in_stock_products / 10000,
    categories: data.categories / 10000,
    active_products: data.active_products / 10000,
    taxes: data.taxes / 10000,
    customers: data.customers / 10000,
    admins: data.admins / 10000
  };

  const chartData = React.useMemo(
    () => [
      {
        label: 'Orders',
        data: [
          ['Active', processedData.active_Orders],
          ['Paid', processedData.paid_Orders],
          ['Canceled', processedData.canceled_Orders],
          ['Pending', processedData.pending_Orders]
        ]
      },
      {
        label: 'Continuous Data',
        data: [
          ['Revenue', processedData.revenue],
          ['In Stock Products', processedData.in_stock_products],
          ['Active Products', processedData.active_products],
          ['Taxes', processedData.taxes],
          ['Customers', processedData.customers],
          ['Admins', processedData.admins]
        ]
      }
    ],
    [processedData]
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left', stacked: true }
    ],
    []
  );

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  );

  return (
    <div
      style={{
        width: '100%',
        height: '500px'
      }}
    >
      <Chart data={chartData} axes={axes} series={series} tooltip />
    </div>
  );
};

export default React.memo(HomeChart);
