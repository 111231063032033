import React, { useState } from "react";

const VideoLinksInput = ({ initialLinks, onChange }) => {
  const [links, setLinks] = useState(initialLinks || []);

  const handleChange = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index] = value;
    setLinks(updatedLinks);
    onChange(updatedLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
    onChange(updatedLinks);
  };

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} className="mb-3">
          <label>Video Link {index + 1}:</label>
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              value={link}
              onChange={(e) => handleChange(index, e.target.value)}
            />
            <button
              type="button"
              className="btn btn-danger ml-2"
              onClick={() => handleRemoveLink(index)}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <button type="button" className="btn btn-primary" onClick={handleAddLink}>
        Add Video Link
      </button>
    </div>
  );
};

export default VideoLinksInput;
