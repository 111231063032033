import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import Select from "react-select";
import "./style.css";

import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import PopUp from "../../components/popup";
import UseGeneral from "../../customHooks/useGeneral";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const ProductsPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [productData, setProductData] = useState({});
  const [originalData, setOriginalData] = useState(null);
  const [showAddProd, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [password, setPassword] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [hideMulti, setHideMulti] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [product_id, set_product_id] = useState({});
  const [allCats, setAllCats] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewer, setViewer] = useState(false);
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const productsHeader = [
    // {
    //   label: "Number",
    //   dataIndex: "number",
    //   search: true,
    //   sort: true,
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     const handleBlur = async (product_id, product_number) => {
    //       set_product_id(null);
    //       setArrangeNumber(product_id, product_number);
    //     };

    //     const handleFocus = () => {
    //       setCurrentNumber(row?.number);
    //       set_product_id(row?.id);
    //     };

    //     const handleClick = () => {
    //       set_product_id(row?.id);
    //     };

    //     return (
    //       <div
    //         style={{ width: "fit-content", cursor: "pointer" }}
    //         onClick={handleClick}
    //       >
    //         <input
    //           style={{ width: "120px", cursor: "pointer" }}
    //           type="text"
    //           onChange={(e) =>
    //             setProductData({ id: row?.id, number: e.target.value })
    //           }
    //           onFocus={() => handleFocus()}
    //           defaultValue={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           value={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           disabled={loader}
    //           className={
    //             product_id === row?.id
    //               ? "MR_input_form_element active"
    //               : "MR_input_form_element lazy"
    //           }
    //           onBlur={async (e) => {
    //             await handleBlur(row?.id, e.target.value);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == 'ar' ? "اسم المنتج" : "Product Name",
      dataIndex:
        language == 'ar' ? "product_translations[0]?.nameProduct" : "title_en",
      type: "children",
      children: ({ row }) => {
        return (
          <p className="text-dark">
            {row?.product_translations && row?.product_translations?.length
              ? language == "ar"
                ? row?.product_translations[0]?.nameProduct
                : row?.product_translations[1]?.nameProduct
              : null}
          </p>
        );
      },
    },
    {
      label: "صور المنتج",
      type: "children",
      children: ({ row }) => {
        return (
          <div style={{ display: "flex" }}>
            {row?.product_images && row?.product_images?.length
              ? row?.product_images?.map((item) => {
                  return (
                    <img
                      role="button"
                      src={BASE_URL + item?.image_url}
                      width={200}
                      height={100}
                      style={{
                        margin: '20px',
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                      onClick={() => setViewer(BASE_URL + item?.image_url)}
                    />
                  );
                })
              : null}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? "سعر المنتج" : "Product Price",
      dataIndex: "price",
    },
    // {
    //   label: language == 'ar' ? " الخصم" : "Discount",
    //   dataIndex: "discount",
    // },
    {
      label: language == 'ar' ? " النقاط" : "Points",
      dataIndex: "points",
    },
    {
      label: language == 'ar' ? "المخزن" : "Stock",
      dataIndex: "stock",
    },

    {
      label: language == 'ar' ? "حالة المنتج" : "Product Status",
      dataIndex: "status",
      type: "children",
      children: ({ row }) => {
        return (
          <div className={`${row.status ? "text-success" : "text-danger"}`}>
            {row.status
              ? language == 'ar'
                ? "مفعل"
                : "Approved"
              : language == 'ar'
              ? "غير مفعل"
              : "Not Approved"}
          </div>
        );
      },
    },
    // {
    //   label: language == 'ar' ? " الفئة الفرعية" : "Subcategory",
    //   type: "children",
    //   children: ({ row }) => {
    //     return (
    //       <div>
    //         {row?.subcategoryproduct
    //           ?.map((item) => {
    //             return language == "ar"
    //               ? [item?.subcategory?.title_ar]
    //               : [item?.subcategory?.title_en];
    //           })
    //           .join(" -- ")}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({row}) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },
    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${!row.status ? "text-danger" : "text-success"}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button
                className={row.status ? "btn btn-danger" : "btn btn-success"}
              >
                {row.status
                  ? language == "ar"
                    ? "إلغاء التفعيل"
                    : "Not Approve"
                  : language == "ar"
                  ? "تفعيل"
                  : "Approve"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // setImg(row.image)
                // console.log(row.image)
                // setChangeStatusModal(true);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const getAdminProducts = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/allProducts`)
      .then((res) => {
        console.log(res?.data[0]);
        setProducts(res?.data);
        setOriginalData(res?.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/get_all_for_admin?${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(
            language == 'ar'
              ? "هناك مشكلة في تحميل الفئات"
              : "Something Went Error"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategories();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const [state, setState] = useState("-1");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    getAdminProducts();
    getCategories();
  }, []);

  useEffect(() => {
    if (
      originalData &&
      originalData?.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (
        searchValue.length > 0 ||
        state != "-1" ||
        selectedCat != "" ||
        selectedSubCat != "" ||
        dateTo != "" ||
        dateFrom != ""
      ) {
        const newData = originalData.filter((prod) => {
          const prodDate = new Date(prod.created_at);
          const fromDate = new Date(dateFrom);
          const toDate = new Date(dateTo);
          console.log(prod, dateFrom, dateTo);
          if (
            !prod?.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            searchValue &&
            searchValue?.length &&
            !prod.id.toString()?.toLowerCase()?.includes(searchValue) &&
            !prod?.title_en?.toLowerCase()?.includes(searchValue) &&
            !prod.price.toString()?.toLowerCase()?.includes(searchValue) &&
            !prod.num_order.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }

          if (state != "-1" && !prod.hidden.includes(state)) {
            return false;
          }
          if (
            selectedCat != "" &&
            !prod.subcategory.category.id.toString().includes(selectedCat)
          ) {
            return false;
          }
          if (
            selectedSubCat != "" &&
            !prod.subcategory.id.toString().includes(selectedSubCat)
          ) {
            return false;
          }

          if (dateFrom != "" && !(prodDate >= fromDate)) {
            return false;
          }
          if (dateTo != "" && !(prodDate <= toDate)) {
            return false;
          }

          return true;
        });
        setProducts(newData);
      } else {
        setProducts(originalData);
      }
    }
  }, [
    searchValue,
    state,
    originalData,
    selectedCat,
    selectedSubCat,
    dateTo,
    dateFrom,
  ]);
  // ======================---------------------- New Features ------------------------==================
  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?

    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/${id}?${token}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setSubCats([]);
        } else {
          setSubCats([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubCats([]);
      })
      .finally(() => {});
  };
  useEffect(() => {
    setSelectedSubCat('');
    if (selectedCat) {
      getSubCategories(selectedCat);
    } else {
      setSubCats([]);
    }
  }, [selectedCat]);
  const handleShow_hide = async (isArray) => {
    const token = localStorage.getItem("SediHishamAdmindata");
    setChangeStatusLoading(true);

    axios
      .get(`${BASE_URL}products/update/state/${rowData.id}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        toast.success(res?.data);
        getAdminProducts();
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };
  const [quantityMulti, setQuantityMulti] = useState(false);
  const [changeMultiQuantity, setChangeMultiQuantity] = useState(0);
  const [subCatsMulti, setSubCatsMulti] = useState(false);
  const [changeMultiSubCats, setChangeMultiSubCats] = useState([]);
  const [categories, setCategories] = useState(null);
  const handleChangeMultiSubCats = async () => {
    const token = localStorage.getItem('SediHishamAdmindata');
    console.log(selectedRows);
    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join('**green**'),
            subcategory_ids:
              item?.subcategoryproduct && item?.subcategoryproduct?.length
                ? [
                    item?.subcategoryproduct
                      ?.map((subItem) => subItem.subcategory_id)
                      ?.join('**green**'),
                    changeMultiSubCats
                      ?.map((item) => item?.value)
                      ?.join('**green**'),
                  ]?.join('**green**')
                : changeMultiSubCats
                    ?.map((item) => item?.value)
                    ?.join('**green**'),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join('**green**'),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(item?.quantity),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join('**green**'),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?${token}`,
            product,
            {
              headers: {
                'Content-Type': 'application/json',
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status === 'success') {
            toast.success(res.message);
            getAdminProducts();
            setHideMulti(false);
          } else if (res.status === 'error' || res.status === 'faild') {
            toast.error(res.message);
          } else {
            toast.error(
              language === 'ar' ? 'حدث خطأ ما' : 'Something Went Error'
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === 'ar' ? 'حدث خطأ ما' : 'Something Went Error');
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };
  const getAllSubCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  useEffect(() => {
    if (!subCatsMulti) {
      setChangeMultiSubCats([]);
    } else {
      getAllSubCategories();
    }
  }, [subCatsMulti]);
  const [openSheetUpload, setOpenSheetUpload] = useState(false);
  const handleChangeMultiQuantity = async () => {
    const token = localStorage.getItem('SediHishamAdmindata');
    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join('**green**'),
            subcategory_ids: item?.subcategoryproduct
              ?.map((subItem) => subItem.subcategory_id)
              ?.join('**green**'),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join('**green**'),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(quantityMulti),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join('**green**'),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?${token}`,
            product,
            {
              headers: {
                'Content-Type': 'application/json',
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status === 'success') {
            toast.success(res.message);
            getAdminProducts();
            setHideMulti(false);
          } else if (res.status === 'error' || res.status === 'faild') {
            toast.error(res.message);
          } else {
            toast.error(
              language === 'ar' ? 'حدث خطأ ما' : 'Something Went Error'
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === 'ar' ? 'حدث خطأ ما' : 'Something Went Error');
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };

  const handleEditMulti = () => {
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach((item) => {
        window.open(
          `/editporduct/${item?.id}`,
          "_blank",
          "noopener,noreferrer"
        );
      });
    }
  };
  const [selectedRows, setSelectedRows] = useState(null);
  const [deletedData, setDeleteData] = useState(false);
  const deleteCategory = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");
    setLoader(true);
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}products/delete/${item.id}?${token}`)
          .then((res) => {
            toast.success(res.message);
            getAdminProducts();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setLoader(false);
          });
      });
    }
  };
  // ======================---------------------- New Features ------------------------==================

  const handleExport = () => {
    const dataToExport = products.map((product) => product);

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `Products_${new Date().toLocaleDateString()}.xlsx`);
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == 'ar' ? 'المنتجات' : "Products"}</h5>
            <div
              className="btn btn-success"
              onClick={() => window.open("/addproduct", "_blanck")}
            >
              {language != "ar" ? "Add New Product" : "إضافة منتج جديد"}
            </div>
          </div>

          <div className="row gy-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="">{language == 'ar' ? "بحث" : "Search"}</label>
              <input
                type="text"
                placeholder={language == 'ar' ? "البحث..." : "Search..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value?.toLowerCase())}
              />
            </div>
            {/* <div className="col-md-4">
              <label className="d-block" htmlFor="">
                {language == 'ar' ? "حالة الظهور" : "Status"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="-1">
                  {language == 'ar' ? "اختر حالة الظهور" : "Select Status"}
                </option>
                <option value="0">
                  {language == 'ar' ? "ظاهر" : "apparent"}
                </option>
                <option value="1">
                  {language == 'ar' ? "مخفي" : "hidden"}
                </option>
              </select>
            </div> */}
            {/* <div className="col-md-4">
              <label htmlFor="">
                {language == 'ar' ? "الفئة" : "Category"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedCat(e.target.value)}
              >
                <option value={""}>
                  {language == 'ar' ? "اختر فئة..." : "Select Category"}
                </option>
                {allCats && allCats?.length >= 1
                  ? allCats?.map((cat, index) => {
                      return (
                        <option value={cat.id}>
                          {language == 'ar' ? cat?.title_ar : cat?.title_en}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">
                {language == 'ar' ? "الفئة الفرعية" : "Select SubCategory"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedSubCat(e.target.value)}
              >
                <option disabled value={""}>
                  {language == 'ar'
                    ? "اختر فئة فرعية..."
                    : "Select Subcategory...."}
                </option>
                {subCats && subCats?.length >= 1
                  ? subCats?.map((subCat, index) => {
                      return (
                        <option key={index} value={subCat.id}>
                          {language == 'ar'
                            ? subCat?.title_ar
                            : subCat?.title_en}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div> */}
            <div className="col-md-4">
              <label htmlFor="">{language == 'ar' ? "من" : "From"}:</label>
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == 'ar' ? "إلى" : 'to'}:</label>
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>
          </div>
          <div className="rowDiv">
            <button
              className="btn btn-danger"
              style={{ cursor: "pointer", margin: "20px" }}
              onClick={() => {
                handleExport();
              }}
            >
              {language == "ar"
                ? "تحميل شيت المنتجات"
                : "Download Products Sheet"}
            </button>

            {/* <button
                className="btn btn-danger"
                style={{ cursor: "pointer", margin: "20px" }}
                onClick={() => {
                  openSheetUpload(true)
                }}
              >
                {language == "ar" ? "رفع شيت المنتجات" : "Upload Products Sheet"}
              </button> */}
          </div>

          <PopUp
        open={deletedData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " حذف "
                  : 'Delete'
                : language == 'ar'
                ? " حذف "
                : " Delete "}
              {language == 'ar' ? 'هذه المنتجات ' : "This Products"}
            </h5>
            {/* <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type="text" onChange={(e) => setPassword(e.target.value)} /> */}
            <button
              onClick={() => deleteCategory(true)}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />{" "}
          {/* هنا يمكنك إضافة باقي عناصر التصفية كما تريد */}

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout
              headers={productsHeader}
              data={products}
              setSelectedRows_t={setSelectedRows}
            />
          )}
        </div>
      </div>
      <PopUp
        open={viewer}
        setOpen={setViewer}
        title={""}
        children={
          <div className="">
            {viewer && viewer && (
              <img
                style={{ objectFit: "contain" }}
                src={viewer}
                width={300}
                height={300}
              />
            )}
          </div>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
              {rowData.status
                ? language == 'ar'
                  ? " إلغاء تفعيل "
                  : 'Not Approve'
                : language == 'ar'
                ? " تفعيل "
                : " Approve "}
              {language == 'ar' ? 'هذا المنتج' : "This Product"}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={hideMulti}
        setOpen={setHideMulti}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? 'هل تريد بالفعل ' : 'Do You Want'}{" "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " إخفاء "
                  : 'Hide'
                : language == 'ar'
                ? " إظهار "
                : " Show "}
              {language == 'ar' ? 'هذه المنتجات ' : "This Products"}
            </h5>

            <button
              onClick={() => handleShow_hide(true)}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
     
      <PopUp
        open={changeMultiQuantity}
        setOpen={setChangeMultiQuantity}
        title={""}
        children={
          <div className="">
            <p>{language == "ar" ? "الكمية الجديدة" : "New Quantity"}</p>
            <input
              type="number"
              onChange={(e) => setQuantityMulti(e.target.value)}
            />

            <button
              onClick={() => handleChangeMultiQuantity(true)}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={subCatsMulti}
        setOpen={setSubCatsMulti}
        title={""}
        children={
          <div className="">
            <>
              <div className="field_input col-md-6">
                <label htmlFor="">
                  {language == 'ar' ? 'الفئة الفرعية' : 'Subcategory '}
                </label>
                {/* <SelectWithSearch
                    options={subCategories}
                    getClientData={setSubCatData}
                    defaultValue={productData?.subcategory_id}
                  /> */}
                <Select
                  placeholder={
                    language == 'ar' ? "الفئات الفرعيه" : 'Subcategory'
                  }
                  isMulti
                  value={changeMultiSubCats}
                  onChange={(e) => {
                    setChangeMultiSubCats(e);
                  }}
                  // value={selectedSubCategories}
                  name="subcategories"
                  options={allCats?.map((item, index) => {
                    return {
                      label: language == "ar" ? item.title_ar : item?.title_en,
                      value: item.id,
                    };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </>

            <button
              onClick={() => handleChangeMultiSubCats(true)}
              className="popup_agree_btn"
            >
              {loader ? <Loader /> : language == 'ar' ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />{" "}
    </>
  );
};

export default ProductsPage;
