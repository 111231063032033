import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { exitModal } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import "./style.css";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const AddProductPage = () => {
  const location = useLocation();
  const { language } = UseGeneral();
  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const hasOptions = location?.state?.hasOptions;
  const [addLoading, setAddLoading] = useState(false);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: hasOptions ? 1 : 0,
    count: '',
    pricePerUnit: '',
    category_id: location?.state?.category_id,
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [isKilo, setIsKilo] = useState(false);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [uploadImages, setUploadImages] = useState(false);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/findall?${token}`)
      .then((res) => {
        setCategories(res?.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [productData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    getCategories();
    getAllProducts();
    console.log(subCatId);
  }, []);
  console.log(location?.state);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?
    console.log(location?.state);
    if (location?.state != null) {
      const token = localStorage.getItem("SediHishamAdmindata");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories_for_admin/${location?.state?.category_id}?${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(
              res?.data?.result?.filter((item) => item?.hidden == "0")
            );
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    } else {
      const token = localStorage.getItem("SediHishamAdmindata");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories_for_admin/${id}?${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(
              res?.data?.result?.filter((item) => item?.hidden == "0")
            );
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }
  };

  useEffect(() => {
    if (data) {
      getSubCategories(data?.id || []);
    } else {
      setSubCategories([]);
    }
  }, [data]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == 'faild') {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : 'Something Went Error');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(productData);
    console.log(products);
  }, [productData]);

  const [selectedOptions, setSelectedOptions] = useState(null);

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleDeleteOldImages = (index) => {
    const updatedImages = [...imagesUrl];
    updatedImages.splice(index, 1);
    setImagesUrl(updatedImages);
  };
  const handleAddNewProduct = async () => {
    setAddLoading(true);
    const dataSet = {
      ...newProd,
      category_id: newProd?.category_id ? parseInt(newProd?.category_id) : 0,
      price: parseFloat(newProd?.price),
      stock: parseFloat(newProd?.stock),
      image_urls: imagesUrl,
      fees_tax: false,
    };

    const token = localStorage.getItem("SediHishamAdmindata");

    try {
      const response = await fetch(`${BASE_URL}products/update/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataSet),
      });

      const res = await response.json();

      if (response.ok) {
        toast.success("Success");
        // getCategories();
        setImagesUrl([]);
        setSelectedImages([]);
        setSelectedSubCategories([]);
        setProductPdf(null);
        setProductSku(null);
      } else if (res.status === "error" || res.status === "faild") {
        toast.error(res.message);
      } else {
        toast.error(language === 'ar' ? "حدث خطأ ما" : 'Something Went Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddLoading(false);
      // setNewCat({
      //   title_ar: "",
      //   title_en: "",
      //   color: "",
      // });
      // setSelectedFile(null);
    }
  };

  const handleUploadImages = async () => {
    const arr = [];
    setUploadImages(true);
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("file", selectedImages[i]);
      await axios
        .post(`${BASE_URL}products/upload/product-image`, formData)
        .then((res) => {
          arr.push(res?.data?.imagePath);
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);
    setUploadImages(false);

    setImagesUrl([...arr]);
  };

  const [productPdf, setProductPdf] = useState(null);
  const handleUploadFiles = async () => {
    const formData = new FormData();
    formData.append("file", productPdf);
    await axios
      .post(`${BASE_URL}products/upload/product-pdf`, formData)
      .then((res) => {
        setNewProd({ ...newProd, product_pdf: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [productSku, setProductSku] = useState(null);
  const handleUploadSKU = async () => {
    const formData = new FormData();
    formData.append("file", productSku);
    await axios
      .post(`${BASE_URL}products/upload/product-sku`, formData)
      .then((res) => {
        setNewProd({ ...newProd, sku: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  const { id } = useParams();
  const getProductById = async () => {
    await axios
      .get(`${BASE_URL}products/${id}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        setNewProd({
          ...res?.data,
          name_ar: res?.data?.product_translations[1]?.nameProduct,
          name_en: res?.data?.product_translations[0]?.nameProduct,
          description_ar: res?.data?.product_translations[1]?.description,
          description_en: res?.data?.product_translations[0]?.description,
          quantity: res?.data?.stock,
        });
        setImagesUrl(res?.data?.product_images?.map((item) => item?.image_url));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
    getAllProducts();
    getProductById();
  }, [language]);
  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">
          {language == 'ar' ? "إضافة منتج جديد" : "Add New Product"}
        </h3>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewProduct();
        }}
      >
        <div className="fields row gy-4">
          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                {language == 'ar' ? ' اسم المنتج باللغة ' : ' Product Name In '}
                {lang.prod_name
                  ? language == 'ar'
                    ? "الإنجليزية"
                    : " english "
                  : language == 'ar'
                  ? " العربية "
                  : " arabic "}
              </label>

              <div class="toggle-switch">
                <input
                  class="toggle-input"
                  id="prod_name"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_name: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_name"></label>
              </div>
            </div>
            <textarea
              // type='text'
              value={lang.prod_name ? newProd.name_en : newProd.name_ar}
              onChange={(e) => {
                lang.prod_name
                  ? setNewProd({
                      ...newProd,
                      name_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      name_ar: e.target.value,
                    });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                {language == 'ar'
                  ? ' وصف المنتج باللغة '
                  : "Product Description In "}
                {lang.prod_desc
                  ? language == 'ar'
                    ? "الإنجليزية"
                    : " english "
                  : language == 'ar'
                  ? " العربية "
                  : " arabic "}
              </label>

              <div class="toggle-switch">
                <input
                  checked={lang.prod_desc}
                  class="toggle-input"
                  id="prod_desc"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_desc: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_desc"></label>
              </div>
            </div>

            <textarea
              // type='text'
              value={
                lang.prod_desc ? newProd.description_en : newProd.description_ar
              }
              onChange={(e) => {
                lang.prod_desc
                  ? setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == 'ar' ? "سعر المنتج " : " Price "}
            </label>
            <input
              value={newProd.price}
              type="number"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  price: e.target.value,
                });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">{language == 'ar' ? "الكمية" : "Quantity"}</label>
            <input
              value={newProd.stock}
              type="number"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  stock: e.target.value,
                });
              }}
            />
          </div>

          <div className="field_input col-md-6">
            <label htmlFor="">{language == 'ar' ? "النقاط" : "Points"}</label>
            <input
              type="number"
              value={newProd.points}
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  discount: e.target.value,
                });
              }}
            />
          </div>
          {categories && categories?.length ? (
            <>
              <div className="field_input col-md-6">
                <label htmlFor="">
                  {language == 'ar' ? 'الفئة' : 'Category'}
                </label>
                <select
                  name=""
                  id=""
                  value={newProd?.
                    category_id
                    }
                  onChange={(e) => {
                    setNewProd({ ...newProd, category_id: e.target.value });
                  }}
                >
                  {categories?.map((item) => {
                    return (
                      <option value={item?.id}>
                        {language == "ar"
                          ? item?.category_translations[1]?.name
                          : item?.category_translations[0]?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          ) : null}
        </div>

        <div className="images_container my-3 porder prorder-2 rounded">
          <h4>
            {language == 'ar' ? "اختر صور المنتج" : "Enter Product Image"}
          </h4>

          <div className="images">
            <label className="image" htmlFor="mul_images">
              <img
                className="avatar"
                src={
                  "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                }
                alt=""
              />
            </label>
            <input
              type="file"
              multiple
              name=""
              id="mul_images"
              className="d-none"
              onChange={handleImageChange}
            />
            {selectedImages.length >= 1
              ? selectedImages.map((imageFile, index) => {
                  return (
                    <div className="image">
                      <img src={URL.createObjectURL(imageFile)} alt="" />
                      <div
                        className="delete_btn"
                        onClick={() => handleDeleteImage(index)}
                      >
                        {exitModal}
                      </div>
                    </div>
                  );
                })
              : null}

            {imagesUrl.length >= 1
              ? imagesUrl.map((imageFile, index) => {
                  return (
                    <div className="image">
                      <img src={BASE_URL + imageFile} alt="" />
                      <div
                        className="delete_btn"
                        onClick={() => handleDeleteOldImages(index)}
                      >
                        {exitModal}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (!uploadImages) {
                handleUploadImages();
              }
            }}
            className="popup_agree_btn"
          >
            {uploadImages ? (
              <Loader />
            ) : language == 'ar' ? (
              "رفع الصور"
            ) : (
              "Upload Image"
            )}
          </button>
        </div>
        <div className="images_container my-3 porder prorder-2 rounded">
          <h4>{language == 'ar' ? "اختر ملف المنتج " : "Enter Product PDF"}</h4>

          <div className="images">
            <label className="image" htmlFor="pdf">
              <img
                className="avatar"
                src={
                  "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                }
                alt=""
              />
            </label>
            <input
              type="file"
              multiple
              name=""
              id="pdf"
              className="d-none"
              onChange={(e) => setProductPdf(e.target.files[0])}
            />
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleUploadFiles();
            }}
            className="popup_agree_btn"
          >
            {uploadImages ? (
              <Loader />
            ) : language == 'ar' ? (
              "رفع الملف "
            ) : (
              "Upload File"
            )}
          </button>
        </div>
        {/* start new  */}
        <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar' ? "كود ال sku" : "Enter Product SKU Code"}
            </h4>

            <input
              type="text"
              name=""
              value={newProd?.sku_code}
              onChange={(e) =>
                setNewProd({ ...newProd, sku_code: e.target.value })
              }
            />
          </div>
        </div>
        <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar' ? "اختر صورة ال SKU" : "Enter Product SKU"}
            </h4>

            <div className="images">
              <label className="image" htmlFor="SKU">
                <img
                  className="avatar"
                  src={
                    "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                  }
                  alt=""
                />
              </label>
              <input
                type="file"
                multiple
                name=""
                id="SKU"
                className="d-none"
                onChange={(e) => setProductSku(e.target.files[0])}
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleUploadSKU();
              }}
              className="popup_agree_btn"
            >
              {uploadImages ? (
                <Loader />
              ) : language == 'ar' ? (
                "رفع صورة SKU "
              ) : (
                "Upload SKU Image"
              )}
            </button>
          </div>
        </div>

        <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar' ? "رابط الفيديو" : "Enter Product Video Link"}
            </h4>

            <input
              type="text"
              name=""
              id=""
              value={newProd?.video_url}
              onChange={(e) =>
                setNewProd({ ...newProd, video_url: e.target.value })
              }
            />
          </div>
        </div>

        <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar'
                ? "حدد الشركات المصنعة للمنتج:"
                : "Select Manufacturers of product:"}
            </h4>

            <input
              type="text"
              name=""
              id=""
              value={newProd?.manufacter}
              onChange={(e) =>
                setNewProd({ ...newProd, manufacter: e.target.value })
              }
            />
          </div>
        </div>

        <button disabled={addLoading} className="popup_agree_btn">
          {addLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
        </button>
      </form>
    </div>
  );
};

export default AddProductPage;
