import './style.css'
import Switch from "react-switch";

export default function Preferences() {
  return (
    <div className="rowDiv flex-2-1 page_padding">
      <form className="preference_form">
        <div>
          <h5>1- Language</h5>
          <div className="input-group">
            <select>
              <option>English</option>
            </select>
          </div>
        </div>

        <div>
          <h5>2- Currency</h5>
          <div className="input-group">
            <select>
              <option selected>Select Your Currency</option>
              <option>AED</option>
              <option>SYP</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>
        </div>
        
        
        <div>
           <h5>3 - Vat</h5>
           <div style={{display:"flex",alignItems:"center" , gap:"10px",margin:"15px 0px"}}>
              <p>Turn On/Off Tax</p>
              <Switch 
        offColor="#888"
        onColor="#7DC37D"
        offHandleColor="#fff"
        onHandleColor="#7DC37D"
        checkedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✔</div>}
        uncheckedIconn={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✘</div>}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
           </div>
           <div style={{display:"flex", alignItems:"center",gap:"10px" }}>
             <p style={{margin:"auto 0px" }}>Turn On/Off Tax</p>
             <input style={{width:"fit-content"}} type="number"/>
           </div>
        </div>

        <div>
          <h5>4- Point System</h5>
          <div style={{display:"flex",alignItems:"center" , gap:"10px"}}>
             <p>Turn On/Off Point System</p>
             <Switch 
        offColor="#888"
        onColor="#7DC37D"
        offHandleColor="#fff"
        onHandleColor="#7DC37D"
        checkedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✔</div>}
        uncheckedIconn={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✘</div>}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
          </div>
        </div>

        <div>
          <h5>5- Store Inventory</h5>
          <div style={{display:"flex",alignItems:"center" , gap:"10px"}}>
             <p>Turn On/Off Store Inventory</p>
             <Switch 
        offColor="#888"
        onColor="#7DC37D"
        offHandleColor="#fff"
        onHandleColor="#7DC37D"
        checkedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✔</div>}
        uncheckedIconn={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✘</div>}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
          </div>

          <div style={{display:"flex",alignItems:"center" , gap:"10px" ,margin:"14px 0px"}}>
             <p>Turn On/Off Stocks Alerts</p>
             <Switch 
        offColor="#888"
        onColor="#7DC37D"
        offHandleColor="#fff"
        onHandleColor="#7DC37D"
        checkedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✔</div>}
        uncheckedIconn={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>✘</div>}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
          </div>
        </div>

        <div className='btns-group'>
          <button className='save_btn'>Save</button>
          <button className='cancel_btn'>Cancel</button>
        </div>
      </form>
    </div>
  )
}
