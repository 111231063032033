import './style.css'

export default function Currencies() {
  return (
    <div className="rowDiv flex-2-1 page_padding">
      <form className='currency-form'>
         <div className="currency-input">
            <label>SYP to AED : </label>
            <input type="number"/>
         </div>

         <div className="currency-input">
            <label>SYP to USD : </label>
            <input type="number"/>
         </div>

         <div className="currency-input">
            <label>SYP to EUR : </label>
            <input type="number"/>
         </div>

         <div className='btns-group'>
            <button className='cancel_btn'>Cancel</button>
            <button className='save_btn'>Save</button>
         </div>
      </form>
    </div>
  )
}
