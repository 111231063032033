import './style.css';

export default function GeneralSettings() {
  return (
    <div className="rowDiv flex-2-1 page_padding">
      <form className="setting-form">
       
       <div>
       <h5 style={{fontWeight:"bold"}}>1- Personal Details</h5>
        <div className="input-group">
           <label>Full Name</label>
           <input type="text" placeholder="Enter full name"/>
        </div>

        <div className="input-group">
           <label>Family</label>
           <input type="text" placeholder="Enter family name"/>
        </div>

        <div className="input-group">
           <label>Phone Number</label>
           <input type="text" placeholder="Enter Phone Number"/>
        </div>

        <div className="input-group">
           <label>Email</label>
           <input type="email" placeholder="Enter Phone Number"/>
        </div>

        <div className="input-group">
           <label>Password</label>
           <input type="password" placeholder="Enter Password"/>
        </div>

        <div style={{display:"flex",flexDirection:"column"}}>
           <p style={{marginBottom:"0px" , padding:"0px"}}>Gender</p>
           <div style={{display:"flex" , gap:"20px"}}>
           <div style={{display:"flex" ,flexDirection:"row" , alignItems:"center",gap:"10px"}}>
              <label>male</label>
              <input type="radio" name="gender" style={{width:"fit-content"}}/>
           </div>

           <div  style={{display:"flex" ,flexDirection:"row" , alignItems:"center",gap:"10px"}}>
              <label>female</label>
              <input type="radio" name="gender" style={{width:"fit-content"}}/>
           </div>
           </div>
        </div>
       </div>

        <div>
        <h5 style={{marginTop:'20px' , fontWeight:"bold"}}>2- Site Logo</h5>
        <div className='input-group'>
          <label>Select a images of Product ( JPEG or PNG ):</label>
          <input type="file" accept='image/png , image/jpeg'/> 
        </div>
        </div>

        <div>
        <h5 style={{marginTop:'20px' , fontWeight:"bold"}}>Bank Informations</h5>
        <div style={{display:"flex"  , gap:"40px"}}>
            <div>
            <div className='input-group'>
          <label>Bank Name</label>
          <input type='text' placeholder='Enter Bank Name'/>
        </div>

        <div className='input-group'>
          <label>Account Number</label>
          <input type='text' placeholder='Enter Account Number'/>
        </div>
        
        <div className='input-group'>
          <label>Statement Number</label>
          <input type='text' placeholder='Enter Account Number'/>
        </div>
            </div>

            <div className='textarea-group'>
               <label>Message</label>
               <textarea></textarea>
            </div>
        </div>
        </div>

        <div className='btns-group'>
          <button className='save_btn'>Save</button>
          <button className='cancel_btn'>Cancel</button>
        </div>
      </form>
    </div>
  )
}
