export const links = (email) => {
  return [
    {
      to: "/",
      icon: "fas fa-home",
      labelArabic: "الرئيسية",
      labelEnglish: "Home",
    },
    {
      to: "/categories",
      icon: "fas fa-th-large",
      labelArabic: "الفئات",
      labelEnglish: "Categories",
    },
    {
      to: "/products",
      icon: "fas fa-box",
      labelArabic: "المنتجات",
      labelEnglish: "Products",
    },
    {
      to: "/Orders",
      icon: "fas fa-calendar-check",
      labelArabic: "الطلبات",
      labelEnglish: "Orders",
    },
    {
      to: "/ideas",
      icon: "fas fa-lightbulb",
      labelArabic: "الطبخات",
      labelEnglish: "Cooks",
    },
    {
      to: "/Jops",
      icon: "fas fa-briefcase",
      labelArabic: "الوظائف",
      labelEnglish: "Jops",
    },
    {
      to: "/coupons",
      icon: "fas fa-gift",
      labelArabic: "الكوبونات",
      labelEnglish: "Coupons",
    },
    {
      to: "/users",
      icon: "fas fa-user-cog",
      labelArabic: "المستخدمين الإداريين",
      labelEnglish: "Administrators",
    },
    {
      to: "/customers",
      icon: "fas fa-users",
      labelArabic: "الزبائن",
      labelEnglish: "Customers",
    },
    {
      to: "/News",
      icon: "fas fa-newspaper",
      labelArabic: "الأخبار",
      labelEnglish: "News",
    },
    {
      to: "/Activity",
      icon: "fas fa-running",
      labelArabic: "الفعاليات والنشاطات",
      labelEnglish: "Activities",
    },
    {
      to: "/Messages",
      icon: "fas fa-comments",
      labelArabic: "الرسائل",
      labelEnglish: "Messages",
    }, {
      to: "/Pages",
      icon: "fas fa-comments",
      labelArabic: "الصفحات",
      labelEnglish: "Pages",
    },
    {
      to:"/settings",
      icon:"fa-solid fa-gear",
      labelArabic:"الاعدادات",
      labelEnglish:"Setting"
    }
  ];
};
