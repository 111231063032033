import { NavLink, Outlet } from "react-router-dom";
import './style.css';

export default function Settings() {
  return (
    <div className="rowDiv flex-2-1 page_padding">
       <div className="setting-tabs-container">
       <h2>General Settings</h2>
        <div className="settings-tabs">
            <NavLink  className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/generalSettings">General Settings</NavLink>
            <NavLink  className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/preferences">Preferences</NavLink>
            <NavLink  className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/shipmentfee">ShipmentFee</NavLink>
            <NavLink  className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/currencies">Currencies</NavLink>
        </div>
       </div>

       <Outlet/>
    </div>
  )
}
