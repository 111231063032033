import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { Loader } from "rsuite";
import { exitModal, plus } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import "./style.css";
import ReactQuill from "react-quill";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const EditNews = () => {
  const location = useLocation();
  const { language } = UseGeneral();
  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const hasOptions = location?.state?.hasOptions;
  const [addLoading, setAddLoading] = useState(false);
  const { id } = useParams();
  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: hasOptions ? 1 : 0,
    count: '',
    pricePerUnit: '',
    category_id: location?.state?.category_id,
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [allNews, setAllNews] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [isKilo, setIsKilo] = useState(false);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [news, setNews] = useState([
    {
      id: 1,
      new_id: "",
      price: "",
      count: "",
    },
  ]);

  const [uploadImages, setUploadImages] = useState(false);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}categories/findall?${token}`)
      .then((res) => {
        setCategories(res?.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [newData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    getCategories();
    getAllNews();
    console.log(subCatId);
  }, []);
  console.log(location?.state);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?
    console.log(location?.state);
    const token = localStorage.getItem("SediHishamAdmindata");
    await axios
      .get(`${BASE_URL}news/allNews`)
      .then((res) => {
        console.log(res);
        setSubCategories(res?.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    getSubCategories();
  }, []);

  const getAllNews = async () => {
    const token = localStorage.getItem("SediHishamAdmindata");

    await axios
      .get(`${BASE_URL}news/getAll_for_admin?${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllNews(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == 'faild') {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : 'Something Went Error');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(newData);
    console.log(news);
  }, [newData]);

  const [selectedOptions, setSelectedOptions] = useState(null);

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleAddNewNew = async () => {
    const dataSet = {
      ...newProd,
      image_url: imagesUrl,
      isvideo: newProd?.video_url && newProd?.video_url?.length ? true : false,
    };

    const token = localStorage.getItem("SediHishamAdmindata");

    try {
      const response = await fetch(`${BASE_URL}news/update/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataSet),
      });

      const res = await response.json();

      if (response?.ok) {
        toast.success("New Editted Successfully");
        // getCategories();
        // setImagesUrl([]);
        setSelectedImages([]);
        // setSelectedSubCategories([]);
        // setNewPdf(null);
        // setNewSku(null);
      } else if (res.status === "error" || res.status === "faild") {
        toast.error(res.message);
      } else {
        toast.error(language === 'ar' ? "حدث خطأ ما" : 'Something Went Error');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddLoading(false);
      // setNewCat({
      //   title_ar: "",
      //   title_en: "",
      //   color: "",
      // });
      // setSelectedFile(null);
    }
  };

  const handleUploadImages = async () => {
    const arr = [];
    setUploadImages(true);
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("file", selectedImages[i]);
      await axios
        .post(`${BASE_URL}cooks/upload/cook-image`, formData)
        .then((res) => {
          arr.push(res?.data?.imagePath);
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);
    setUploadImages(false);

    setImagesUrl([...imagesUrl, ...arr]);
  };

  const [newPdf, setNewPdf] = useState(null);
  const handleUploadFiles = async () => {
    const formData = new FormData();
    formData.append("file", newPdf);
    await axios
      .post(`${BASE_URL}news/upload/new-pdf`, formData)
      .then((res) => {
        setNewProd({ ...newProd, new_pdf: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [newSku, setNewSku] = useState(null);
  const handleUploadSKU = async () => {
    const formData = new FormData();
    formData.append("file", newSku);
    await axios
      .post(`${BASE_URL}cooks/upload/cook-image`, formData)
      .then((res) => {
        setNewProd({ ...newProd, image_background_url: res.data?.imagePath });
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  const handleDeleteOldImages = (index) => {
    const updatedImages = [...imagesUrl];
    updatedImages.splice(index, 1);
    setImagesUrl(updatedImages);
  };
  const getProductById = async () => {
    await axios
      .get(`${BASE_URL}news/${id}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log("res?.data", res);
        setNewProd({
          title_ar: res?.data?.new_translations[0]?.title,
          title_en: res?.data?.new_translations[1]?.title,
          description_ar: res?.data?.new_translations[0]?.descrition,
          description_en: res?.data?.new_translations[1]?.descrition,
          ...res?.data,
        });
        setImagesUrl(res?.data?.image_url?.map((item) => item?.image_url));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setDataLoading(false);
      });
  };

  useEffect(() => {
    getProductById();
  }, []);
  return (
    <>
      {!newProd?.id ? (
        <Loader />
      ) : (
        <div className="add_new_container" style={{ padding: "10px" }}>
          <div className="d-flex mb-5 mt-3 align-items-center ">
            <h3 className="">
              {language == 'ar' ? "إضافة خبر جديد" : "Add New News"}
            </h3>
          </div>

          <form
            style={{ padding: "10px" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewNew();
            }}
          >
            <div className="fields row gy-4">
              <div className="field_input  col-md-6">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? ' اسم الخبر باللغة ' : ' New Name In '}
                    {lang.prod_name
                      ? language == 'ar'
                        ? "الإنجليزية"
                        : " english "
                      : language == 'ar'
                      ? " العربية "
                      : " arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          prod_name: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <textarea
                  // type='text'
                  value={lang.prod_name ? newProd.title_en : newProd.title_ar}
                  onChange={(e) => {
                    lang.prod_name
                      ? setNewProd({
                          ...newProd,
                          title_en: e.target.value,
                        })
                      : setNewProd({
                          ...newProd,
                          title_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>
                  {language == 'ar'
                    ? "الوصف بالانجليزية"
                    : "Description Into English"}
                </h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.description_en}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, description_en: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div
                className="ingrediants mt-4"
                style={{
                  minHeight: "300px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <h4>{language == 'ar' ? "الوصف" : "Description"}</h4>
                <div className="d-flex align_items-center justify-content-between">
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={newProd?.description_ar}
                    onChange={(e) => {
                      return setNewProd({ ...newProd, description_ar: e });
                    }}
                    style={{
                      minHeight: "200px",
                      color: "black",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              {/* <div
            className="ingrediants mt-4"
            style={{
              minHeight: "300px",
              color: "black",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            <h4>
              {language == 'ar'
                ? "المكونات بالانجليزية"
                : "Ingrediants Into English"}
            </h4>
            <div className="d-flex align_items-center justify-content-between">
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.description_ar}
                onChange={(e) => {
                  return setNewProd({ ...newProd, description_ar: e });
                }}
                style={{
                  minHeight: "200px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div
            className="ingrediants mt-4"
            style={{
              minHeight: "300px",
              color: "black",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            <h4>{language == 'ar' ? "المكونات" : "Ingrediants"}</h4>
            <div className="d-flex align_items-center justify-content-between">
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.description_en}
                onChange={(e) => {
                  return setNewProd({ ...newProd, description_en: e });
                }}
                style={{
                  minHeight: "200px",
                  color: "black",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </div>
          </div> */}
              {/* {categories && categories?.length ? (
            <>
              <div className="field_input col-md-6">
                <label htmlFor="">
                  {language == 'ar' ? 'الخبرات' : 'News'}
                </label>
                <Select
                  placeholder={language == 'ar' ? "الخبرات " : 'News'}
                  isMulti
                  value={newProd?.recommended_new_ids}
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      recommended_new_ids: e?.value,
                    });
                  }}
                  // value={selectedSubCategories}
                  isDisabled={subCategories == null}
                  name="subcategories"
                  options={
                    subCategories &&
                    subCategories != null &&
                    Array.isArray(subCategories) &&
                    subCategories.length > 0 &&
                    subCategories != null &&
                    subCategories.map((item, index) => {
                      return {
                        label:
                          language == "ar"
                            ? item?.new_translations[0]?.nameNew
                            : item?.new_translations[1]?.nameNew,
                        value: parseInt(item.id),
                      };
                    })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </>
          ) : null} */}
            </div>

            <div className="images_container my-3 porder prorder-2 rounded">
              <h4>
                {language == 'ar'
                  ? "اختر صور السلايدر"
                  : "Enter New Slider Images"}
              </h4>

              <div className="images">
                <label className="image" htmlFor="mul_images">
                  <img
                    className="avatar"
                    src={
                      "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                    }
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  multiple
                  name=""
                  id="mul_images"
                  className="d-none"
                  onChange={handleImageChange}
                />
                {selectedImages.length >= 1
                  ? selectedImages.map((imageFile, index) => {
                      return (
                        <div className="image">
                          <img src={URL.createObjectURL(imageFile)} alt="" />
                          <div
                            className="delete_btn"
                            onClick={() => handleDeleteImage(index)}
                          >
                            {exitModal}
                          </div>
                        </div>
                      );
                    })
                  : null}
                    {imagesUrl.length >= 1
                  ? imagesUrl.map((imageFile, index) => {
                      return (
                        <div className="image">
                          <img src={BASE_URL + imageFile} alt="" />
                          <div
                            className="delete_btn"
                            onClick={() => handleDeleteOldImages(index)}
                          >
                            {exitModal}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (!uploadImages) {
                    handleUploadImages();
                  }
                }}
                className="popup_agree_btn"
              >
                {uploadImages ? (
                  <Loader />
                ) : language == 'ar' ? (
                  "رفع الصور"
                ) : (
                  "Upload Image"
                )}
              </button>
            </div>
          
            {/* start new  */}

            {/* <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar' ? "اختر صورة الطبخة" : "Enter Cooks Image"}
            </h4>

            <div className="images">
              <label className="image" htmlFor="SKU">
                <img
                  className="avatar"
                  src={
                    "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                  }
                  alt=""
                />
              </label>
              <input
                type="file"
                multiple
                name=""
                id="SKU"
                className="d-none"
                onChange={(e) => setNewSku(e.target.files[0])}
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleUploadSKU();
              }}
              className="popup_agree_btn"
            >
              {uploadImages ? (
                <Loader />
              ) : language == 'ar' ? (
                "رفع صورة SKU "
              ) : (
                "Upload SKU Image"
              )}
            </button>
          </div>
        </div> */}

            {/* <div className="my-4">
          <div className="images_container my-3 porder prorder-2 rounded">
            <h4>
              {language == 'ar'
                ? "رابط الفيديو ف السلايدر"
                : "Enter Cook Video Link In slider"}
            </h4>

            <input
              type="text"
              name=""
              id=""
              onChange={(e) =>
                setNewProd({
                  ...newProd,
                  video_url_instead_slider: e.target.value,
                })
              }
            />
          </div>
        </div> */}
            <div className="my-4">
              <div className="images_container my-3 porder prorder-2 rounded">
                <h4>
                  {language == 'ar' ? "رابط الفيديو" : "Enter New Video Link"}
                </h4>

                <input
                  type="text"
                  name=""
                  id=""
                  value={newProd?.video_url}
                  onChange={(e) =>
                    setNewProd({
                      ...newProd,
                      video_url: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <button disabled={addLoading} className="popup_agree_btn">
              {addLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditNews;
